.email-invites-modal {
  .actions-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    gap: 0.5rem;
    flex-wrap: wrap;
  }

  .role-wrapper {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex: 1;
  }

  .role-wrapper .MuiFormLabel-root {
    white-space: nowrap;
    flex-shrink: 0;
  }

  .role-select-wrapper {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex-grow: 1;
    justify-content: flex-end;
  }

  button {
    flex-shrink: 0;
  }

  @media (max-width: 600px) {
    .actions-wrapper {
      flex-direction: column;
      align-items: flex-start;
    }

    .role-select-wrapper {
      justify-content: flex-start;
    }

    button {
      width: 100%;
    }
  }

  .MuiChipDelete-root svg {
    width: 1.5rem;
    height: 1.5rem;
    color: #000000;
  }
}
